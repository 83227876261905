<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="配置类型" prop="type" >
        <a-select v-model="form.type" placeholder="请输入配置类型" >
          <a-select-option :value="0">类型</a-select-option>
          <a-select-option :value="1">品牌</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="配置名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入配置名称" />
      </a-form-model-item>
      <a-form-model-item label="图片" prop="icon" v-if="form.type == 1">
        <a-upload
          :customRequest="fileUploadRequest"
          list-type="picture-card"
          :showUploadList="false"
          :beforeUpload="beforeUpload"
          @change="handleChange">
          <img v-if="form.icon" :src="form.icon" alt="图片" style="height:104px;max-width:300px"/>

          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于50M,推荐规格:702*300 </span>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getConfiguration, addConfiguration, updateConfiguration } from '@/api/user/configuration'
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      uploadLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        name: null,

        type: null,

        icon: null,

        createTime: null,

        deleted: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '配置类型不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '配置名称不能为空', trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '图片不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    /**
     * 上传图片
     * @param info
     */
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false
        this.form.icon = info.file.originFileObj.ossUrl
      }
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if (file.size > 50 * 1024 * 1000) {
          this.$message.warning('图片大小不能超过50M')
          return reject(false)
        }

        var fileType = file.type
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片')
          return reject(false)
        }
        resolve(true)
      })
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true
      debugger
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          debugger
          const ossUrl = res.url
          fileInfo.file.ossUrl = ossUrl
          fileInfo.file.ossName = ossUrl
          fileInfo.onSuccess()
        }
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        type: null,
        icon: null,
        createTime: null,
        deleted: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getConfiguration({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateConfiguration(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addConfiguration(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
