import request from '@/utils/request'


// 查询电池配置信息列表
export function listConfiguration(query) {
  return request({
    url: '/user/batteryConfiguration/list',
    method: 'get',
    params: query
  })
}

// 查询电池配置信息分页
export function pageConfiguration(query) {
  return request({
    url: '/user/batteryConfiguration/page',
    method: 'get',
    params: query
  })
}

// 查询电池配置信息详细
export function getConfiguration(data) {
  return request({
    url: '/user/batteryConfiguration/detail',
    method: 'get',
    params: data
  })
}

// 新增电池配置信息
export function addConfiguration(data) {
  return request({
    url: '/user/batteryConfiguration/add',
    method: 'post',
    data: data
  })
}

// 修改电池配置信息
export function updateConfiguration(data) {
  return request({
    url: '/user/batteryConfiguration/edit',
    method: 'post',
    data: data
  })
}

// 删除电池配置信息
export function delConfiguration(data) {
  return request({
    url: '/user/batteryConfiguration/delete',
    method: 'post',
    data: data
  })
}
